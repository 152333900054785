<template>
  <div class="space-y-4">
    <div class="flex-1 h-10 flex justify-end content-center gap-3 no-print" v-if="hasOtherPSC">
      <div class="py-2 text-sm">This station has more than 1 PSC</div>
      <span class="relative z-0 inline-flex shadow-sm rounded-md"> 
        <span class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-blue-600">
          {{pscName}}
        </span>
        <button
          @click="toggleModal(true)"
          type="button"
          class="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-blue-600 hover:text-white hover:bg-button">
          Change PSC
        </button>
      </span>
      <asom-modal title="Change PSC" v-model="showPSCSelection" :dismissible="false">
        <div class="space-y-2">
          <asom-form-field
            label="Select new PSC"
            description="New activities in PSC would be linked to the newly selected PSC">
            <asom-input-radio-group
              v-model="selectedPSCId"
              :options="pscOptions"
            />
          </asom-form-field>
          <div class="flex flex-row-reverse pt-4 gap-4">
            <asom-button
              @click="onConfirm"
              text="Confirm"
            />
            <asom-button
              @click="onCancel"
              text="Cancel"
              variant="secondary"
            />
          </div>
        </div>
      </asom-modal>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      showPSCSelection: false,
      selectedPSCId: this.$store.state.cashManagement.stationCashAccounts.selectedPSCId,
    }
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      pscName: "cashManagement/stationCashAccounts/pscName",
      pscOptions: "cashManagement/stationCashAccounts/pscOptions",
    }),
    hasOtherPSC() {
      return this.pscOptions && this.pscOptions.length && this.pscOptions.length > 1;
    }
  },
  methods: {
    toggleModal(value) {
      this.showPSCSelection = value;
    },
    onConfirm() {
      this.toggleModal(false);
      if (this.pscId != this.selectedPSCId) {
        this.$store.commit('cashManagement/stationCashAccounts/setSelectedPSCId', this.selectedPSCId);
      }
    },
    onCancel() {
      this.toggleModal(false);
      this.selectedPSCId = this.pscId;
    }
  }
}
</script>